






















import VueAsyncComputed from 'vue-async-computed';
import {Component, Vue} from 'vue-property-decorator';
import GenericFilterComponent from '@/components/search/GenericFilterComponent.vue';
import GenericFilter from '@/components/search/GenericFilter';
import SearchConfiguration from '@/components/search/SearchConfiguration';
import GenericFilterResults from '@/components/search/GenericFilterResults.vue';

Vue.use(VueAsyncComputed);

@Component({
    components: { GenericFilterComponent, GenericFilterResults }
})
export default class TribeSearch extends Vue {
    private loading = true;
    private initialized = true;
    private url = '';

    private filters: {[key: string]: GenericFilter} = {
        serverId: {
            label: 'Server',
            field: 'serverId',
            type: 'autocomplete',
            autocomplete: {
                resource: 'server',
                idField: 'id',
                labelField: 'name'
            },
            valueType: 'number',
            selected: []
        },
        tribeName: {
            label: 'Name',
            field: 'tribeId',
            type: 'autocomplete',
            autocomplete: {
                resource: 'tribe',
                idField: 'tribeId',
                labelField: 'name'
            },
            valueType: 'number',
            selected: []
        },
        tribeId: {
            label: 'Tribe Id',
            field: 'tribeId',
            type: 'autocomplete',
            autocomplete: {
                resource: 'tribe',
                idField: 'tribeId',
                labelField: 'tribeId'
            },
            valueType: 'number',
            selected: []
        }
    }

    private searchConfiguration: SearchConfiguration = {
        resource: 'tribe',
        filters: this.filters,
        columns: {
            name: {
                label: 'Name',
                field: 'name'
            },
            type: {
                label: 'Server',
                field: 'serverId'
            },
            inventory: {
                label: 'Tribe Id',
                field: 'tribeId'
            },
            tribe: {
                label: 'Owner',
                field: 'owner'
            }
        }
    }
}
